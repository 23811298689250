#sideMenu{
    width: 261px;
    text-align: start;
    background-color: #43425d;
    color:#fff;
    height: 100vh;
}
#sideMenu a{
    color: #fff !important;
    line-height: 2.5;
}
#sideMenu a img{
    margin-top: -5px;
}
.nav-item{
    height: 56px;
}
.nav-item.active{
    background-color: rgba(0,0,0,0.10196078431372549 );
    height: 56px;
    line-height: 2.5;
}
.nav-item.active:hover{
    border-left: none !important;
}
.nav-item.active a{
    letter-spacing: 3px;
    font-weight: bold;
    font-family: arial;
}
.nav-item:hover{
    background-color: rgba(0,0,0,0.10196078431372549 );
    border-left: 3px solid #a3a0fb;
}