.signup-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 403px;
    text-align: center;
}
.app-logo{
    padding: 0 0 15px 0
}
.txt-msg{
    margin-top: 10px;
}
.fname{
    margin-right: 11.5px;
    width: 190px;
    border: none;
    border-bottom: 1px solid #e9e9f0;
    color: #777689;
    font-size: 15px;
    padding: 8px;
    font-family: source sans pro;
} 
.lname{
    margin-left: 11.5px;
    width: 190px;
    border: none;
    border-bottom: 1px solid #e9e9f0;
    color: #777689;
    font-size: 15px;
    padding: 8px;
    font-family: source sans pro;
}
.txt_field{
    width: 403px;
    border: none;
    border-bottom: 1px solid #e9e9f0;
    color: #777689;
    padding: 8px;
    font-size: 15px;
    font-family: source sans pro;
}

.checkbox-container{
    text-align: left;
    padding: 15px 0 15px 0;
}
.check_box{
    width: 19px;
    height: 19px;
}
label.checkbox_txt {
    margin-left: 25px;
    font-size: 15px;
    font-family: source sans pro;
}
.sign_btn{
    margin-top: 10px;
}
.signup{
    width: 186px;
    height: 51px;
    background-color: #43425d;
    border: none;
    border-radius: 3px;
    color: #e9e9f0;
}
.haveaccount{
    margin-top: 10px;
}
.privacy {
    padding: 40px 0 0 0;
}