.forgot_center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-family: source sans pro;
    text-align: center;
}
.forgot_logo{
    margin-top: 75px;
    padding: 0 0 15px 0;
}
.forgot-txt{
    font-size: 18px;
    font-family: source sans pro;
    color: #d9dadc;
}
.email_input{
    margin-bottom: 25px;
}
.email_txt{
    width: 403px;
    border: none;
    border-bottom: 1px solid #e9e9f0;
    color: #777689;
    padding: 8px;
    font-size: 15px;
    font-family: source sans pro;

}
.req_btn{
    width: 230px;
    height: 51px;
    background-color: #43425d;
    border: none;
    border-radius: 3px;
    color: white;
}