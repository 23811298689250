.center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 403px;
    text-align: center;
}
.app-logo{
    padding: 0 0 15px 0;
}
.msg{
    font-size: 18px;
    font-family: source sans pro;
    color: #d9dadc;
}
.login-msg{
    padding: 0 0 10px 0;
}

.username-input, .password-input{
    margin-bottom: 25px;
}
.form-input{
    width: 403px;
    border: none;
    border-bottom: 1px solid #e9e9f0;
    color: #777689;
}

.checkbox-container{
    padding: 15px 0 15px 0
}
.checkmark{
    position: absolute;
    width: 19px;
    height: 19px;
}
.checkbox-label, .forget, .form-input, .policy, .forget, .checkbox-label{
    font-family: source sans pro;
    font-size: 15px;
}

.button-container{
    padding: 50px 0 0 0;
    font-size: 18px;
    font-family: source sans pro;
}

.login-btn{
    width: 186px;
    height: 51px;
    margin-right: 10px;
    background-color: #43425d;
    border: none;
    border-radius: 3px;
    color: #ffffff;
}
.signup-btn{
    width: 186px;
    padding: 13px 60px;
    margin-left: 10px;
    background-color: #ffffff;
    border:1px solid #43425d;
    border-radius: 3px;
    color: #43425d;
    text-decoration: none;
}
.forget{
    text-decoration: none;
    color: #43425d;
    margin-left: 194px;
}
.privacy{
    padding: 80px 0 0 0;
}
.policy, .checkbox-label{
    text-decoration: none;
    color: #43425d;
}
