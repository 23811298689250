.banner{
    width: 100%;
}
.layout-container {
	min-width: 0;
}

.layout-container-content {
	min-height: 100vh;
}

.layout-main {
	display: block;
}

#headerView{
    border-bottom: none !important;
}
.dropdown-toggle::after{
    color: #fff;
}